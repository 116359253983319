import React, { useEffect, useState, useMemo } from 'react';
import './HomeScreen.css';
import TextField from '@mui/material/TextField';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Divider, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import Slide from '@mui/material/Slide';
import { EditNoteRounded, LaunchRounded } from '@mui/icons-material';
import ResultEditable from './ResultEditable';

const saleProduceArr = (detailAll, day, daypart) => {
  return detailAll?.length>0 || detailAll!==null? detailAll?.filter((his) => 
    his?.date?.split('T')?.[0] === day && 
  his?.part === daypart
    ):[]
}

const defineLucky = (detailAll, day, daypart) => {
  return detailAll?.length>0 || detailAll!==null? detailAll?.filter((his) => 
    his?.date === day && 
    his?.daypart === daypart
    ):[]
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HomeScreen() {
  let wonAudio = new Audio('./songs/win.wav')
  let loseAudio = new Audio('./songs/lose.wav')
  const navigate = useNavigate();
  const {authTokens, selLotteryDate, userType, handleLogout} = useAuth()
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  var selDate = selLotteryDate?.date
  var selDayPart = selLotteryDate?.datePart
  const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
  const [currentTime, setCurrentTime] = useState(moment().tz('Asia/Yangon').format('HH:mm:ss'))
  var currentDay = moment().tz('Asia/Yangon').format('YYYY-MM-DD')
  const [currentDayPart, setCurrentDayPart] = useState(moment().tz('Asia/Yangon').format('A'))
  const [result, setResult] = useState('')
  const [editResult, setEditResult] = useState(result)
  const [historyAll, setHistoryAll] = useState([])
  const [history, setHistory] = useState([])
  const [userHistory, setUserHistory] = useState([])
  const [luckAll, setLuckAll] = useState([])
  const [limitAmount, setLimitAmount] = useState('0')
  const [lucky, setLucky] = useState('')
  const [hotNum, setHotNum] = useState([])
  const [takeLoad, setTakeLoad] = useState(0)
  const [errorMes, setErrorMes] = useState('')
  const [success, setSuccess] = useState(false)
  const [realTimeUser, setRealTimeUser] = useState([])
  var inputDate = moment().tz('Asia/Yangon').format();
  var morningClosedTime = userType?.morClose
  var eveningClosedTime = userType?.envClose

  const [open, setOpen] = useState(false);
  const [passOpen, setPassOpen] = useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [passScroll, setPassScroll] = React.useState('paper');
  const [newpassword, setnewPassword] = React.useState('');
  const [repassword, setRePassword] = React.useState('');

  const handleClickOpen = () => {
    setScroll('paper');
    setOpen(true);
  };

  const handlePassClickOpen = () => {
    setPassScroll('paper');
    setPassOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePassClose = () => {
    setPassOpen(false);
  };

  const [editOpen, setEditOpen] = useState(false)

  const handleEditOpen = () => {
    setEditOpen(true)
  }

  const handleEditClose = () => {
    setEditOpen(false)
  }

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const currentMonday = moment().isoWeekday(1).format('YYYY-MM-DD')
  const currentTueday = moment().isoWeekday(2).format('YYYY-MM-DD')
  const currentWedday = moment().isoWeekday(3).format('YYYY-MM-DD')
  const currentThuday = moment().isoWeekday(4).format('YYYY-MM-DD')
  const currentFriday = moment().isoWeekday(5).format('YYYY-MM-DD')

  const defineSplitlens = (data) => {
      if(data === ''){
        return 0
      } else {
        if(data?.includes('R')){
          return 3
        } else if(data?.includes('A') ||
        data?.includes('N') ||
        data?.includes('W') ||
        data?.includes('X')
        ) {
          return 1
        } else if(data?.includes('Z')) {
          var splZ = data?.split('Z')
          var last = splZ?.[splZ?.length-1]?.length
          var finalLen = data?.length - last
          return finalLen
        } else if(data?.includes('S') ||
          data?.includes('M')) {
          var len = data?.[1] === 'S' || data?.[1] === 'M'?2:1
          return len
        } else {
          return 2
        }
      }
  }
  var splitLen = defineSplitlens(result);

  useEffect(() => {
    let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistory&userid='+userType?.id+'&adminid='+userType?.adminid+'&date='+selDate+'&part='+selDayPart
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveDigitLimitUser&userid='+userType?.id+'&adminid='+userType?.adminid
                      }),
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLucky&adminid='+userType?.adminid
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLimitAllDigit&adminid='+userType?.adminid
                      }),
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAll&adminid='+userType?.adminid+'&date='+selDate+'&part='+selDayPart
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=userdetail&username='+userType?.username
                      }), 
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAllNoFilterUser&userid='+userType?.id+'&adminid='+userType?.adminid+'&from='+currentMonday+'&to='+currentFriday
                      })
                      */
                    ]).then(response => {
                        //setHistory(response?.[0]?.['data']['data']['result'])
                        setHotNum(response?.[0]?.['data']['data']['result'])
                        //var data = response?.[2]?.['data']['data']['result']
                        //var newdata = data?.filter((dat) => dat?.daypart === selDayPart && dat?.date === selDate)
                        //setLucky(newdata?.[0]?.lucky)
                        //setLuckAll(data)
                        var nextdata = response?.[1]?.['data']['data']['result']
                        setLimitAmount(nextdata?.[0]?.limit_amount === undefined || nextdata===null?'0':nextdata?.[0]?.limit_amount)
                        //setHistoryAll(response?.[4]?.['data']['data']['result'])
                        setRealTimeUser(response?.[2]?.['data']['data']['result'])
                        //setUserHistory(response?.[6]?.['data']['data']['result'])
                      })
                } catch (error) {
                    
                }
            })()
        }
    return () => {isSubscribed = false}
  }, [takeLoad, currentDate, userType?.id, userType?.adminid, userType?.username])

  useEffect(() => {
    let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistory&userid='+userType?.id+'&adminid='+userType?.adminid+'&date='+selDate+'&part='+selDayPart
                      }),
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveDigitLimitUser&userid='+userType?.id+'&adminid='+userType?.adminid
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLucky&adminid='+userType?.adminid
                      }),
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=getLimitAllDigit&adminid='+userType?.adminid
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAll&adminid='+userType?.adminid+'&date='+selDate+'&part='+selDayPart
                      }),
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=userdetail&username='+userType?.username
                      }), 
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=saveHistoryAllNoFilterUser&userid='+userType?.id+'&adminid='+userType?.adminid+'&from='+currentMonday+'&to='+currentFriday
                      })
                    ]).then(response => {
                        setHistory(response?.[0]?.['data']['data']['result'])
                        //setHotNum(response?.[1]?.['data']['data']['result'])
                        var data = response?.[1]?.['data']['data']['result']
                        var newdata = data?.filter((dat) => dat?.daypart === selDayPart && dat?.date === selDate)
                        setLucky(newdata?.[0]?.lucky)
                        setLuckAll(data)
                        //var nextdata = response?.[3]?.['data']['data']['result']
                        //setLimitAmount(nextdata?.[0]?.limit_amount === undefined || nextdata===null?'0':nextdata?.[0]?.limit_amount)
                        setHistoryAll(response?.[2]?.['data']['data']['result'])
                        //setRealTimeUser(response?.[5]?.['data']['data']['result'])
                        setUserHistory(response?.[3]?.['data']['data']['result'])
                      })
                } catch (error) {
                    
                }
            })()
        }
    return () => {isSubscribed = false}
  }, [takeLoad, userType?.id, userType?.adminid, selDate, selDayPart, currentMonday, currentFriday])

  function checkIfDuplicateExists(arr) {
      return new Set(arr).size !== arr.length
  }
  
  useEffect(() => {
    if(result?.split('\n')?.length === 1) {
      var len = result?.[1] === 'S' || result?.[1] === 'M'?2:1
    var checkSt = result?.slice(len)
    var amount = result?.slice(splitLen)
    var spl = result?.split('R')
    var splZ = result?.split('Z')
    var splZZ = result?.includes('ZZ')? result?.split('ZZ') : splZ
    var digits = result?.slice(0,splitLen)
    var newDigit = digits?.replace('Z','')
    var newDig = newDigit?.split('')
    if(checkSt?.includes('S') || checkSt?.includes('M')){
      reset()
    }else if(amount?.includes('S')||amount?.includes('M')||
    amount?.includes('S')||amount?.includes('M')|| amount?.includes('A')||amount?.includes('N')||
    amount?.includes('X')||amount?.includes('W')|| amount?.includes('F')||amount?.includes('B')||
    amount?.includes('P')
    ){
      reset()
    }else if(spl?.length > 2){
      reset()
    }else if(splZZ?.length > 2 || splZZ?.[1]?.includes('Z')){
      reset()
    }else if(result?.includes('Z') && checkIfDuplicateExists(newDig)) {
      reset()
    }else if(result?.includes('Z') && result?.includes('R')) {
      reset()
    }else if(result?.includes('X') && result?.includes('R')) {
      reset()
    }else if(result?.includes('X') && result?.includes('Z')) {
      reset()
    }else if(result?.includes('S') && result?.includes('R')) {
      reset()
    }else if(result?.includes('M') && result?.includes('R')) {
      reset()
    }else if(result?.includes('S') && result?.includes('Z')) {
      reset()
    }else if(result?.includes('M') && result?.includes('Z')) {
      reset()
    }else if(result?.includes('N') && result?.includes('R')) {
      reset()
    }else if(result?.includes('W') && result?.includes('R')) {
      reset()
    }else if(result?.includes('B') && result?.includes('R')) {
      reset()
    }else if(result?.includes('F') && result?.includes('R')) {
      reset()
    }else if(result?.includes('L') && result?.includes('R')) {
      reset()
    }else if(result?.includes('P') && result?.includes('R')) {
      reset()
    }else if(result?.includes('A') && result?.includes('R')) {
      reset()
    }else if(spl?.[0]?.length === 2 && result?.includes('R')){
      if(spl?.[0]?.[0] === spl?.[0]?.[1]){
        reset()
      }
    }else if(result?.includes('R') && result?.length < 3) {
      reset()
    }else if((result?.includes('E') && result?.includes('M')) || (result?.includes('E') && result?.includes('S'))) {
      reset()
    }else if(result?.[0] === 'Z' || result?.[1] === 'Z') {
      reset()
    }else if(result?.split('E').length > 3 || (result?.[0] === 'E' && result?.[1] === 'E') || (result?.[0] === 'E' && result?.[2] === 'E')){
      reset()
    }else if(result?.includes('EE')){
      reset()
    } else if((result?.[0] === 'S' || result?.[0] === 'M') && (result?.[1] === '1' ||
      result?.[1] === '2' ||
      result?.[1] === '3' ||
      result?.[1] === '4' ||
      result?.[1] === '5' ||
      result?.[1] === '6' ||
      result?.[1] === '7' ||
      result?.[1] === '8' ||
      result?.[1] === '9')){
        reset()
    }
    }
  },[result, splitLen])
  const onClick = (button) => {
    if(button === "Save") {
      SaveDigit();
    }
    else if(button === "C") {
      setErrorMes('')
      reset();
    }
    else {
      if(button === 'A' ||
      button === 'N' ||
      button === 'X' ||
      button === 'W'
      
      ) {
        setResult(button)
      } else if(button === 'S' || button === 'M') {
        setResult(result+button)
        if(result?.length > 0){
          if(result?.[0] === '1' ||
            result?.[0] === '2' ||
            result?.[0] === '3' ||
            result?.[0] === '4' ||
            result?.[0] === '5' ||
            result?.[0] === '6' ||
            result?.[0] === '7' ||
            result?.[0] === '8' ||
            result?.[0] === '9'
            ) {
              reset()
            } else {
              setResult(result+button)
          }
        }
        if(result?.length > 1){
          if(result?.[2] === '1' ||
            result?.[2] === '2' ||
            result?.[2] === '3' ||
            result?.[2] === '4' ||
            result?.[2] === '5' ||
            result?.[2] === '6' ||
            result?.[2] === '7' ||
            result?.[2] === '8' ||
            result?.[2] === '9'
            ) {
              setResult(result+button)
            } else {
              reset()
          }
        }
      } else if (button === "F" ||
        button === "L" ||
        button === "B" ||
        button === "P"
      ) {
        if(result?.length > 0) {
          if(result?.[0] === '0' ||
          result?.[0] === '1' ||
          result?.[0] === '2' ||
          result?.[0] === '3' ||
          result?.[0] === '4' ||
          result?.[0] === '5' ||
          result?.[0] === '6' ||
          result?.[0] === '7' ||
          result?.[0] === '8' ||
          result?.[0] === '9'
          ) {
            setResult(result?.[0]+button)
          } else {
            reset()
          }
        } else {
          reset()
        }
      } else if(button === 'R') {
        setResult(result+button)
      } else if(button === 'Z') {
        setResult(result+button)
      } else if(button === 'E'){
        if(result?.length > 0){
          if(result?.[0] === 'E'){
            var substringBetweenEs = result?.split('E')?.[1]
            var chunks = substringBetweenEs?.match(/\d{2}/g);
            var formattedSubstring = chunks?.join(',');
            if(formattedSubstring === undefined){
              reset()
            }else {
              var wantstring = result.replace(substringBetweenEs, formattedSubstring);
              setResult(wantstring+button)
            }
          } else {
            setResult(button)
          }
        } else {
          setResult(result+button)
        }
      }
      else {
        setResult(result+button)
      }
    }
  }
  const reset = () => {
    setResult('')
  }
  
  const numarray = Array(100).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '0'+String(n+i)
    } else {
      return String(n + i)
    }
  })


const DefineDigit = (digit, result, numarray, amount, first, second, date, part) => {
    if(result?.includes('R') && !result?.includes('E')){
    var newDigit = result?.split('R')
    var firstSplit = newDigit?.[0]
    var firstFirst = firstSplit?.slice(0,2)
    var reverseDigit = firstFirst?.split("").reverse().join("")
    return [{'digit':firstFirst, 'amount':first, 'date':date, 'part':part},{'digit':reverseDigit, 'amount':second, 'date':date, 'part':part}]
    } else if(digit.includes('P')){
    var newPDigit = digit?.replace('P','')
    var digitinclude = numarray.filter(num => num.includes(newPDigit))
    return digitinclude.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else if(digit.includes('A')){
    return ['00','11','22','33','44','55','66','77','88','99'].map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } //else if(digit === 'S'){
    //var digitSEven = numarray.filter(num => parseInt(num) % 2 === 0)
    //return digitSEven.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    //} else if(digit === 'M'){
    //var digitOdd = numarray.filter(num => parseInt(num) % 2 === 1)
    //return digitOdd.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    //} 
    else if(digit === 'SS'){
    var digitSSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 0)
    return digitSSEven.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else if(digit === 'SM'){
    var digitSMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 1)
    return digitSMEven.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else if(digit === 'MM'){
    var digitMMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 1)
    return digitMMEven.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else if(digit === 'MS'){
    var digitMSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 0)
    return digitMSEven.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else if(digit.includes('F')){
    var newFDigit = digit?.replace('F','')
    var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
    return digitFFirst.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else if(digit.includes('L')){
    var newLDigit = digit?.replace('L','')
    var digitLFirst = numarray.filter(num => num?.[1] === newLDigit)
    return digitLFirst.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else if(digit.includes('B')){
    var newBDigit = digit?.replace('B','')
    var digitFirst = numarray.filter(num => 
        (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.length === 1?
        (parseInt(num?.[0])+parseInt(num?.[1])).toString() === newBDigit : 
        (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.[1] === newBDigit
        )
    return digitFirst.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else if(digit.includes('W')){
    return ['05','50','16','61','27','72','38','83','49','94'].map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else if(digit.includes('N')){
    return ['07','70','18','81','24','42','35','53','69','96'].map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else if(digit?.includes('X')){
    return ['01','10','12','21','23','32','34','43','45','54',
    '56','65','67','76','78','87','89','98','90','09'].map(item => ({'digit':item, 'amount':amount, 'date':date, 'part':part}));
    } else if(digit?.includes('Z')){
    if(digit?.includes('ZZ')){
        var newZZDigit = digit?.replace('ZZ','')
        var newArrZZ = newZZDigit?.split('')
        return combinationsTwo(newArrZZ).map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    } else {
        var newZDigit = digit?.replace('Z','')
        var newArrZ = newZDigit?.split('')
        return combinations(newArrZ).map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
    }
    } else if(result?.includes('E')){
      var newEDigit = result?.split('E')?.[1]?.split(',')
      var amountE = result?.split('E')?.[2]
      var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
      var modEArr = []
      if(amountE?.includes('R')){
        newEDigit?.forEach((digit) => {
          if(digit === digit?.split("").reverse().join("")){
            modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
          } else {
            modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
            modEArr?.push({'digit':digit?.split("").reverse().join(""), 'amount':amounts?.[1]})
          }
        })
      } else {
        newEDigit?.forEach((digit) => {
          modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
        })
      }
      return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'date':date, 'part':part}))
    }
    return [{'digit':digit, 'amount':amount, 'date':date, 'part':part}]
}

function removeDuplicates(arr) {
    return arr.filter((item,index) => arr.indexOf(item) === index);
}
const combinationsTwo = (arr) => {
    let set = new Set(arr);
    
    arr = [...set];
    var digit = []
    
    for(let i = 0; i < arr.length - 1; i++){       
    for(let j = i + 1; j < arr.length; j++){ 
        digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i],arr[i]+''+arr[i],arr[j]+''+arr[j]);
    }     
    } 
    return removeDuplicates(digit)
}

const combinations = (arr) => {
    let set = new Set(arr);
    
    arr = [...set];
    var digit = []
    
    for(let i = 0; i < arr.length - 1; i++){       
    for(let j = i + 1; j < arr.length; j++){ 
        digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i]);
    }     
    } 
    return digit
}
const useFilteredDetail = (detailAll) => {
  return useMemo(() => {
    if (!detailAll || detailAll.length === 0) return [];

    return detailAll.filter((his) => {
      return his?.buy === '1';
    });
  }, [detailAll]);
};
const newHistory = useFilteredDetail(history);
const newHistoryAll = useFilteredDetail(historyAll)
const newUserHistory = useFilteredDetail(userHistory)

const MakeDigitArr = () => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0', his?.date, his?.part);
        digits?.push(digitArr)
    })
    return digits?.flat()
}

const MakeDigitArrAll = () => {
  var digits = []
  newHistoryAll?.forEach((his) => {
      var result = his?.result
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var splitLen = defineSplitlens(result);
      var digitFormula = result?.slice(0,splitLen)
      var oneAmount = result?.slice(splitLen)
      var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0', his?.date, his?.part);
      digits?.push(digitArr)
  })
  return digits?.flat()
}

const MakeDigitUserArrAll = () => {
  var digits = []
  newUserHistory?.forEach((his) => {
      var result = his?.result
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var splitLen = defineSplitlens(result);
      var digitFormula = result?.slice(0,splitLen)
      var oneAmount = result?.slice(splitLen)
      var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0', his?.date, his?.part);
      digits?.push(digitArr)
  })
  return digits?.flat()
}

const digitMadeArr = MakeDigitArr()
const digitMadeArrAll = MakeDigitArrAll()
const detailAll = MakeDigitUserArrAll()

const useDetailAmount = (newDetail) => {
  return useMemo(() => {
    const detailAmount = newDetail.reduce((acc, { amount, digit, ...rest }) => {
      const key = digit;
      if (!acc[key]) {
        acc[key] = { digit, ...rest, amount: 0 };
      }
      acc[key].amount += parseInt(amount, 10);
      return acc;
    }, {});

    return Object.values(detailAmount);
  }, [newDetail]);
};

const detailAmount = useDetailAmount(digitMadeArrAll);


const totalAmount = digitMadeArr?.reduce((n, {amount}) => n + parseInt(amount), 0)
const calculateTotalWithLucky = (arr) => {
  var searchUser = arr?.filter((user) => user?.digit === lucky)
  var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
  return totalAmount
}

const calculateTotalWithLuckyAll = (arr, lucky) => {
  var searchUser = arr?.filter((user) => user?.digit === lucky?.[0]?.lucky)
  var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
  return totalAmount
}
var newDigit = result?.split('R')
var firstSplit = newDigit?.[0]

const digitArrForResult = DefineDigit(result?.slice(0,splitLen), result, numarray, result?.slice(defineSplitlens(result)), result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');

var overdigit = [];
/*
var limitAmountCheck = digitArrForResult?.some((digit) => {
  var amountcheck = Array.from(digitMadeArr?.reduce(
    (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
  ), ([digit, amount]) => ({digit, amount}));
  var hotamount = hotNum !== null? Array.from(hotNum?.reduce(
    (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
  ), ([digit, amount]) => ({digit, amount})):[];
  var getDigitAmount = amountcheck?.filter((getDigit) => getDigit?.digit === digit?.digit)
  
  var getHotNumber = hotamount?.filter((hot) => hot?.digit === digit?.digit)
  overdigit = [{
    hotAmount: getHotNumber?.[0]?.amount,
    digit:digit,
    digitamount: getDigitAmount?.[0]?.amount
  }]

  return getHotNumber?.length > 0 && hotNum !== null? 
  getHotNumber?.some((hot) => {
    var getDigit = amountcheck?.filter((a) => a?.digit === hot?.digit)
    return ((getDigit?.[0]?.amount!==undefined? getDigit?.[0]?.amount:0)+
    (parseInt(digit?.amount))) > 
    hot?.amount
  }):
  getDigitAmount?.length > 0?
  getDigitAmount?.some((sumdigit) => {
    return (
      (sumdigit?.amount + parseInt(digit?.amount)) > parseInt(userType?.limit)
    )
  }):
  parseInt(digit?.amount) > parseInt(userType?.limit)
})
*/
const LimitCheckOver = (digitArrForResult, digitMadeArr, hotNum, userType) => {
  return digitArrForResult?.some((digit) => {
    var amountcheck = Array.from(digitMadeArr?.reduce(
      (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
    ), ([digit, amount]) => ({digit, amount}));
    var hotamount = hotNum !== null? Array.from(hotNum?.reduce(
      (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
    ), ([digit, amount]) => ({digit, amount})):[];
    var getDigitAmount = amountcheck?.filter((getDigit) => getDigit?.digit === digit?.digit)
    
    var getHotNumber = hotamount?.filter((hot) => hot?.digit === digit?.digit)
    overdigit = [{
      hotAmount: getHotNumber?.[0]?.amount,
      digit:digit,
      digitamount: getDigitAmount?.[0]?.amount
    }]
  
    return getHotNumber?.length > 0 && hotNum !== null? 
    getHotNumber?.some((hot) => {
      var getDigit = amountcheck?.filter((a) => a?.digit === hot?.digit)
      return ((getDigit?.[0]?.amount!==undefined? getDigit?.[0]?.amount:0)+
      (parseInt(digit?.amount))) > 
      hot?.amount
    }):
    getDigitAmount?.length > 0?
    getDigitAmount?.some((sumdigit) => {
      return (
        (sumdigit?.amount + parseInt(digit?.amount)) > parseInt(userType?.limit)
      )
    }):
    parseInt(digit?.amount) > parseInt(userType?.limit)
  })
}

const CheckWithDigit = (digit) => {
  return detailAmount?.filter((detail) => detail?.digit === digit) || []
}
/*
var checkLimitOver = digitArrForResult?.filter((digit) => {
  return (
    (parseInt(CheckWithDigit(digit?.digit)?.[0]?.amount === undefined ? '0':CheckWithDigit(digit?.digit)?.[0]?.amount)) + parseInt(digit?.amount===undefined?'0':digit?.amount)) > parseInt(limitAmount)
})

var checkLimitCorrect = digitArrForResult?.filter((digit) => {
  return (parseInt(digit?.amount) || 0) < 1
})

var digitAmountOver = ''
var checkDigitAmountOver = digitArrForResult?.some((element) => {
  if(realTimeUser?.[0]?.closedDigit?.split(',')?.includes(element?.digit)){
    digitAmountOver = element
  }
  return realTimeUser?.[0]?.closedDigit?.split(',')?.includes(element?.digit)
}
)
*/
  // amount check limit over all digit
  //check greater than 1
  //lmit amount check give by admin
  const SaveDigit = async() => {
    let messageUrl = mainUrl+'api.php?op=saveDigit';
      setErrorMes('')
      var errorArr = []
      try{
        const promises = result?.split('\n')?.map(async(res) => {
          var splitLen = defineSplitlens(res);
          var newDigit = res?.split('R')
          var firstSplit = newDigit?.[0]
          var digitArrForResult = DefineDigit(res?.slice(0,splitLen), res, numarray, res?.slice(defineSplitlens(res)), res?.includes('R') && res?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):res?.slice(splitLen), res?.includes('R')? newDigit?.[1]:'0');
          var checkLimitCorrect = digitArrForResult?.filter((digit) => {
              return (parseInt(digit?.amount) || 0) < 1 || digit?.digit?.replace(/[^0-9]/g, '')?.length !== 2 || /[a-zA-Z]/.test(digit?.amount)
          })
          var checkLimitOver = digitArrForResult?.filter((digit) => {
              return (
              (parseInt(CheckWithDigit(digit?.digit)?.[0]?.amount === undefined ? '0':CheckWithDigit(digit?.digit)?.[0]?.amount)) + parseInt(digit?.amount===undefined?'0':digit?.amount)) > parseInt(limitAmount)
          })
          var limitCheckOver = LimitCheckOver(digitArrForResult, digitMadeArr, hotNum, userType)
          var digitAmountOver = ''
          var checkDigitAmountOver = digitArrForResult?.some((element) => {
            if(realTimeUser?.[0]?.closedDigit?.split(',')?.includes(element?.digit)){
              digitAmountOver = element
            }
            return realTimeUser?.[0]?.closedDigit?.split(',')?.includes(element?.digit)
          }
          )
          if(checkDigitAmountOver){
            //loseAudio.play()
            //setSuccess(false)
            errorArr?.push(digitAmountOver?.digit+' -> ဒိုင်မှ ပိတ်ထားပါတယ်!')
          }else {
            if(checkLimitOver?.length > 0) {
              //loseAudio.play()
              //setSuccess(false)
              errorArr?.push(checkLimitOver?.[0]?.digit+' -> သတ်မှတ်ပမာဏ ကျော်နေပါတယ်!')
            } else {
              if(checkLimitCorrect?.length > 0 || digitArrForResult?.length < 1){
                //loseAudio.play()
                //setSuccess(false)
                errorArr?.push('ပမာဏ မမှန်ကန်ပါ')
              } else {
                if(limitCheckOver){
                  //loseAudio.play()
                  //setSuccess(false)
                  errorArr?.push(overdigit?.[0]?.digit?.digit+' (ကျန်ပမာဏ -> '+
                  (overdigit?.[0]?.hotAmount !== undefined && overdigit?.[0]?.digitamount !== undefined? overdigit?.[0]?.hotAmount - overdigit?.[0]?.digitamount : 
                  overdigit?.[0]?.hotAmount !== undefined && overdigit?.[0]?.digitamount === undefined? overdigit?.[0]?.hotAmount - 0:
                  overdigit?.[0]?.hotAmount === undefined && overdigit?.[0]?.digitamount !== undefined? parseInt(userType?.limit) - overdigit?.[0]?.digitamount:
                  parseInt(userType?.limit))+')')
                } else {
                  const response = await fetch(messageUrl, {
                    method:'post',
                    headers:{
                      'Content-Type':'application/x-www-form-urlencoded'
                    },
                    body:"result="+res+"&userid="+userType?.id+"&date="+inputDate+"&buy=1&adminid="+userType?.adminid+"&part="+selDayPart
                  })
                  const data = await response.json()
                  return data
                }
              }
            }
          }
        })
        await Promise.allSettled(promises);
        if(errorArr?.length > 0) {
          loseAudio.play()
          setResult('')
          setSuccess(false)
          setErrorMes(errorArr)
          setTakeLoad(takeLoad+1)
        } else {
          wonAudio.play()
          setResult('')
          setTakeLoad(takeLoad+1)
          setErrorMes([result+' သိမ်းပြီး!'])
          setSuccess(true)
        }
      } catch (error) {
        //
      }
  }

  const [currentDateOf, setCurrentDateOf] = useState(moment().tz('Asia/Yangon').format('ddd'))
  useEffect(() => {
    setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
    setInterval(() => setCurrentTime(moment().tz('Asia/Yangon').format('HH:mm:ss')),5000)
    setInterval(() => setCurrentDayPart(moment().tz('Asia/Yangon').format('A')),5000)
    setInterval(() => setCurrentDateOf(moment().tz('Asia/Yangon').format('ddd')),5000)
  },[])
  function DigitStatus() {
    if(currentDateOf === 'Sun' || currentDateOf === "Sat"){
      return 'Closed'
    } else {
      if(realTimeUser?.[0]?.status === 'Open'){
        if(currentDay !== selDate) {
          return 'Closed'
        } else {
          if(currentDayPart === selDayPart){
            if(currentDayPart === 'AM') {
              if(currentTime > realTimeUser?.[0]?.morClose){
                return 'Closed'
              } else {
                return 'Open'
              }
            } else {
              if(currentTime > realTimeUser?.[0]?.envClose){
                return 'Closed'
              } else {
                return 'Open'
              }
            }
          } else {
            return 'Closed'
          }
        }
      } else {
        return 'Closed'
      }
    }
  }
  var digitStatus = userType?.username === 'ymh'?'Open': DigitStatus()
  //var digitStatus = 'Open'

  const mondayAm = saleProduceArr(detailAll,currentMonday, 'AM')

  const mondayPm = saleProduceArr(detailAll,currentMonday, 'PM')

  const tueDayAm = saleProduceArr(detailAll,currentTueday, 'AM')

  const tueDayPm = saleProduceArr(detailAll,currentTueday, 'PM')
  
  const wedDayAm = saleProduceArr(detailAll,currentWedday, 'AM')

  const wedDayPm = saleProduceArr(detailAll,currentWedday, 'PM')

  const thuDayAm = saleProduceArr(detailAll,currentThuday, 'AM')

  const thuDayPm = saleProduceArr(detailAll,currentThuday, 'PM')
  
  const friDayAm = saleProduceArr(detailAll,currentFriday, 'AM')

  const friDayPm = saleProduceArr(detailAll,currentFriday, 'PM')

  const mondayAmLuck = defineLucky(luckAll,currentMonday, 'AM')

  const mondayPmLuck = defineLucky(luckAll,currentMonday, 'PM')

  const tueDayAmLuck = defineLucky(luckAll,currentTueday, 'AM')

  const tueDayPmLuck = defineLucky(luckAll,currentTueday, 'PM')
  
  const wedDayAmLuck = defineLucky(luckAll,currentWedday, 'AM')

  const wedDayPmLuck = defineLucky(luckAll,currentWedday, 'PM')

  const thuDayAmLuck = defineLucky(luckAll,currentThuday, 'AM')

  const thuDayPmLuck = defineLucky(luckAll,currentThuday, 'PM')
  
  const friDayAmLuck = defineLucky(luckAll,currentFriday, 'AM')

  const friDayPmLuck = defineLucky(luckAll,currentFriday, 'PM')

  const [updateMes, setUpdateMes] = useState('')
  const [successMes, setSuccessMes] = useState('')
  const updatePass = async() => {
    let updateUrl = mainUrl+'api.php?op=changePass';
    if(newpassword!=='' || repassword !== ''){
      if(newpassword === repassword){
        await fetch(updateUrl, {
          method:'post',
          headers:{
            'Content-Type':'application/x-www-form-urlencoded'
          },
          body:"password="+newpassword+"&id="+userType?.id
        }).then(() => {
          handlePassClose()
          setnewPassword('')
          setRePassword('')
          setUpdateMes('')
          setSuccessMes('Password ချိန်းပီးပါပီ')
          handleLogout()
        })
      }else {
        setUpdateMes('Password တူအောင်ပြန်ရိုက်ထည့်ပေးပါ')
        setSuccessMes('')
      }
      }else {
        setUpdateMes('Password အသစ်ရိုက်ထည့်ပေးပါ')
        setSuccessMes('')
    }
  }

  return (
    <div
    style={{display:'flex', 
        justifyContent:'center', paddingBottom:10,
        alignItems:'start'}}
    >
      <ResultEditable
      editOpen = {editOpen}
      handleEditClose={handleEditClose}
      Transition={Transition}
      digitStatus = {digitStatus}
      onClick = {onClick}
      result = {result}
      setResult = {setResult}
      digitArrForResult = {digitArrForResult}
      editResult = {editResult}
      setEditResult = {setEditResult}
      defineSplitlens={defineSplitlens}
      DefineDigit={DefineDigit}
      numarray={numarray}
      limitAmount = {limitAmount}
      CheckWithDigit = {CheckWithDigit}
      LimitCheckOver = {LimitCheckOver}
      digitMadeArr = {digitMadeArr}
      hotNum = {hotNum}
      userType = {userType}
      realTimeUser = {realTimeUser}
      />
      <Dialog
      onClose={handlePassClose}
      open={passOpen}
      scroll={passScroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      >
        <DialogTitle style={{fontFamily:'mmfont', fontWeight:'900', textAlign:'center'}} sx={{ m: 0, p: 2 }} id="scroll-dialog-title">
          Password ချိန်းရန်
        </DialogTitle>
        <DialogContent>
          {updateMes!==''?<p style={{color:'red', fontFamily:'mmfont', marginBottom:5}}>{updateMes}</p>:null}
          {successMes!==''?<p style={{color:'red', fontFamily:'mmfont', marginBottom:5}}>{successMes}</p>:null}
          <div style={{display:'flex', flexDirection:'column'}}>
          <TextField
            size='small'
            autoComplete={false}
            id="outlined-basic" variant="outlined" 
            value={newpassword}
            placeholder='New Password'
            onChange={(e) => setnewPassword(e.target.value)}
            type="password"
          />
          <div style={{marginTop:10}}/>
          <TextField 
            autoComplete={false}
            size='small'
            id="outlined-basic" variant="outlined" 
            value={repassword}
            placeholder='Confirm Password'
            onChange={(e) => setRePassword(e.target.value)}
            type="password"
          />
          </div>
        </DialogContent>
        <DialogActions>
          <Button style={{fontFamily:'mmfont'}} onClick={handlePassClose}>
            ပိတ်မည်
          </Button>
          <Button style={{fontFamily:'mmfont'}} onClick={updatePass}>
            သိမ်းမည်
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleClose}
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle style={{fontFamily:'mmfont', fontWeight:'900'}} sx={{ m: 0, p: 2 }} id="scroll-dialog-title">
          ရည်ညွှန်းချက်များ
        </DialogTitle>
        <DialogContent
        style={{marginLeft:'15px', marginRight:'15px', paddingLeft:0, paddingRight:0}}
        >
          <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
          style={{paddingLeft:0}}
          >
            <div>
              <table>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:120, fontWeight:'900'}}>ဂဏန်း</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'mmfont',
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:180, fontWeight:'900'}}
                  >ရိုက်ကွက်</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ဒဲ့ ဂဏန်း (ဥပမာ - 98)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >985000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ငွေတူ R ဂဏန်း (ဥပမာ - 98)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >98R5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ငွေမတူ R ဂဏန်း (ဥပမာ - 98)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >981000R5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>အပါ ဂဏန်း (ဥပမာ - 1 အပါ)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >1P10000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>အပူး ဂဏန်းများ</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >A50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>စုံစုံ ဂဏန်းများ</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >SS50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>မမ ဂဏန်းများ</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >MM50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>စုံမ ဂဏန်းများ</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >SM50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>မစုံ ဂဏန်းများ</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >MS50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ထိပ် ဂဏန်းများ (ဥပမာ - 1 ထိပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >1F50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>နောက် ဂဏန်းများ (ဥပမာ - 1 နောက်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >1L50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ဘရိတ် ဂဏန်းများ (ဥပမာ - 1 ဘရိတ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >1B50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ပါဝါ ဂဏန်းများ</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >W50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>နခတ် ဂဏန်းများ</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >N50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ညီကို ဂဏန်းများ</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >X50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>အခွေ ဂဏန်းများ (123 အခွေ - အပူး မပါ)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >123Z50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>အခွေ ဂဏန်းများ (123 အခွေ - အပူး ပါ)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >123ZZ50000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ငွေတူ ဂဏန်းများ (23,47,93 - R မပါ)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >E23,47,93E5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ငွေတူ ဂဏန်းများ (23,47,93 - R ငွေတူ ပါ)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >E23,47,93ER5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ငွေတူ ဂဏန်းများ (23,47,93 - R ငွေမတူ ပါ)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >E23,47,93E5000R1000</td>
                </tr>
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className='homescreen'>
        <div className='digitscreen'>
          <div className='topbar'>
            <p className='text'>အမည်</p>
          </div>
          <div style={{marginLeft: 20, display:'flex', alignItems:'center'}}>
            <p className='text'>: &nbsp; {userType?.username} | 2D</p>
            <IconButton
            sx={{
              color:'white',
              '&:focus': {
                backgroundColor: 'transparent',
              },
              '&:hover': {
                backgroundColor: 'transparent',
              }
            }}
            onClick={() => {
              handlePassClickOpen()}}
            color="white" aria-label="expand"
            >
              <EditNoteRounded size={12}/>
            </IconButton>
          </div>
          <div style={{marginRight:0, marginLeft:'auto'}}>
          <Button
          style={{padding:'0px 10px', backgroundColor:'rgba(255,255,255,0.2)',
            textDecoration:'none !important', color:'white', textTransform:'capitalize'}}
          onClick={() => {navigate('/'); handleLogout()}}
          >
            Log out
          </Button>
          </div>
        </div>
        <div className='digitscreen'>
          <div className='topbar'>
            <p className='text'>ရက်စွဲ</p>
          </div>
          <div style={{marginLeft: 20}}>
            <p className='text'>: &nbsp; {selDate} {selDayPart}</p>
          </div>
          <div style={{marginLeft: 20}}>
          <Button
          style={{padding:'0px 10px', backgroundColor:'white',
            textDecoration:'none !important', color:'black', textTransform:'lowercase'}}
          onClick={() => navigate('/')}
          >
            Select Date
          </Button>
          </div>
        </div>
        <div className='digitscreen'>
          <div className='topbar'>
            <p className='text'>ပိတ်ချိန်</p>
          </div>
          <div style={{marginLeft: 20}}>
            <p className='text'>: &nbsp; {selDayPart==="AM"? (realTimeUser?.[0]?.morClose===undefined? morningClosedTime:realTimeUser?.[0]?.morClose) : (realTimeUser?.[0]?.envClose===undefined? eveningClosedTime:realTimeUser?.[0]?.envClose)}</p>
          </div>
        </div>
        <div className='digitscreen'>
          <div className='topbarBottom'>
            <p className='text'>တစ်ကွက် အများဆုံး ခွင့်ပြုငွေ</p>
          </div>
          <div style={{marginLeft: 10}}>
            <p className='text'>: &nbsp; {userType?.limit}</p>
          </div>
        </div>
        <div className='digitscreen' style={{paddingBottom:10}}>
          <div className='topbar'>
            <p className='text'>Status</p>
          </div>
          <div style={{marginLeft: 20}}>
            <p className='text'>: &nbsp; {digitStatus}</p>
          </div>
          <div style={{marginLeft: 'auto', marginRight:0}}>
            <Button
            style={{padding:'0px 10px', backgroundColor:'rgba(255,255,255,0.2)', fontFamily:'mmfont',
              textDecoration:'none !important', color:'white', textTransform:'capitalize'}}
              onClick={() => handleClickOpen()}
            >
              ရည်ညွှန်းချက်များ
            </Button>
          </div>
        </div>
        <div style={{marginLeft:10, display:'flex', alignItems:'center', margin:'5px 10px'}}>
          <p style={{color:'black', marginRight:4}}>Hot Digits : &nbsp; </p>
          <div style={{display:'flex'}}>{
          hotNum?.length > 0 || hotNum !== undefined?
          hotNum?.map((hot,index) => 
          <><p style={{color:'red'}}>{hot?.digit}</p><p>{hot?.amount}{index === (hotNum?.length-1)?'':','}</p></>
          )
          :null
          }</div>
        </div>
        <div style={{padding:'3px 10px 0px 10px', display:'flex'}}>
          <p>Result: </p> <p style={{color: success?'#1bbc12':'#d8005d', lineHeight:'20px', fontFamily:'mmfont', overflow:'scroll', whiteSpace:'nowrap'}}>&nbsp; {errorMes !== '' && errorMes?.map((err) => err+' , ')}</p>
        </div>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: "95%"},
          }}
          className='White'
          style={{textAlign:'center'}}
          noValidate
          autoComplete="off"
        >
        <div>
          <Box
          component="form"
          sx={{ p: '2px 0px', boxShadow:'0px', display: 'flex', alignItems: 'center', flex:1,}}
          >
            <TextField
            size='small'
            aria-readonly
            disabled
            value={result}
            id="outlined-disabled"
            placeholder='Press any digit'
            sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                    "& > fieldset": {
                        borderColor: "rgba(0,0,0,0.1)",
                        WebkitTextFillColor: "#000000",
                    },
                    WebkitTextFillColor: "#000000",
                    fontSize:27,
                }
            }}
            InputProps={{
              style: {
                paddingTop:'0px'
              },
              sx: {
                "&.MuiInputBase-root.Mui-disabled": { color: "unset" },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "unset",
                },
              },
              autoComplete: 'off'
            }}
            />
            <Divider sx={{ height: 28 }} orientation="vertical"/>
            <IconButton
            onClick={() => {
              setEditResult(result)
              handleEditOpen()}}
            color="primary" sx={{ p: '10px'}} aria-label="expand"
            >
              <LaunchRounded/>
            </IconButton>
          </Box>

        </div>
        </Box>
        <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value="C"
          style={{fontFamily: 'mmfont'}}
          >
          ဖျက်မည်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "P"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          အပါ
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "R"
          disabled={digitStatus==="Closed"}
          id="digitBox"
          >
          R
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "A"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          အပူး
          </button>
        </div>
        <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "7"
          disabled={digitStatus==="Closed"}
          >
          7
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "8"
          disabled={digitStatus==="Closed"}
          >
          8
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "9"
          disabled={digitStatus==="Closed"}
          >
          9
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "S"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          စုံ
          </button>
        </div>
        <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "4"
          disabled={digitStatus==="Closed"}
          >
          4
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "5"
          disabled={digitStatus==="Closed"}
          >
          5
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "6"
          disabled={digitStatus==="Closed"}
          >
          6
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "M"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          မ
          </button>
        </div>
        <div className='firstrow' style={{alignItems:'flex-start', marginTop:-0.5}}>
          <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          style={{width:'100%'}}
          onClick={(e) => onClick(e.target.value)}
          value = "1"
          disabled={digitStatus==="Closed"}
          >
          1
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          style={{width:'202%', marginTop:3}}
          onClick={(e) => onClick(e.target.value)}
          value = "0"
          disabled={digitStatus==="Closed"}
          >
          0
          </button>
          </div>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "2"
          disabled={digitStatus==="Closed"}
          >
          2
          </button>
          <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          style={{width:'100%'}}
          onClick={(e) => onClick(e.target.value)}
          value = "3"
          disabled={digitStatus==="Closed"}
          >
          3
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          id="digitBox"
          //disableRipple
          style={{width:'100%', marginTop:3}}
          onClick={(e) => onClick(e.target.value)}
          value = "00"
          disabled={digitStatus==="Closed"}
          >
          00
          </button>
          </div>
          <button
          //variant="contained" disableElevation
          className='buttonBox fourthBox'
          //disableRipple
          style={{backgroundColor:'#6798c6 !important', fontFamily:'mmfont'}}
          onClick={(e) => onClick(e.target.value)}
          value = "Save"
          disabled={digitStatus==="Closed" || digitArrForResult?.[0]?.digit === '' || digitArrForResult?.[0]?.amount === ''}
          >
          သိမ်းမည်
          </button>
        </div>
        <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "F"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ထိပ်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "L"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          နောက်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "E"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ငွေတူ
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "B"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ဘရိတ်
          </button>
        </div>
        <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "W"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ပါဝါ
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "N"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          နခတ်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "X"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ညီကို
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "Z"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ခွေ
          </button>
        </div>
        <div style={{padding:10, marginTop:10, justifyContent:'space-between', display:'flex'}}>
        <Button
        size='small'
        style={{
          fontSize:14,
          textDecoration:'none',
          textTransform:'capitalize',
          color:'#6798c6',
          border:'2px solid #6798c6',
          fontWeight:'bold', 
          fontFamily:'mmfont'
        }}
        className='linkText'
        onClick={() => navigate('/history', {
          state:{
            history:history
          }
        })}
        >
          ထိုးဂဏန်းများ
        </Button>
        <Button
        size='small'
        style={{
          fontSize:14,
          textDecoration:'none',
          textTransform:'capitalize',
          color:'#6798c6',
          border:'2px solid #6798c6',
          fontWeight:'bold', 
          fontFamily:'mmfont'
        }}
        className='linkText'
        onClick={() => navigate('/details', {
          state:{
            history:history
          }
        })}
        >
          အကွက်ဇယား
        </Button>
        </div>
        
        <div style={{marginTop:5, paddingLeft:10}}>
          <h4 style={{marginBottom:5, fontFamily:'mmfont'}}>စာရင်းအချုပ် (တစ်ကြိမ်စာ)</h4>
          <table>
            <tbody>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:150,
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>အရောင်းစုစုပေါင်း</td>
                <td
                  style={{border:'1px solid #6798c6', fontWeight:'500',
                  margin:1, width:150, color:'black', padding:'2px 10px'}}
                >{totalAmount}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:150,
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>ကော်မရှင် ({userType?.commission}%)</td>
                <td
                  style={{border:'1px solid #6798c6', fontWeight:'500',
                  margin:1, width:150, color:'black', padding:'2px 10px'}}
                >{(totalAmount/100)*parseInt(userType?.commission)}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:150,
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>
                    ဒဲ့ပေါက် ({lucky === undefined || lucky ===""?'--':lucky})
                  </td>
                <td
                  style={{border:'1px solid #6798c6', fontWeight:'500',
                  margin:1, width:150, color:'black', padding:'2px 10px'}}
                >{calculateTotalWithLucky(digitMadeArr) === undefined?0:calculateTotalWithLucky(digitMadeArr)}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', width:150,
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>စုစုပေါင်း</td>
                <td
                  style={{border:'1px solid #6798c6', fontWeight:'500',
                  margin:1, width:150, padding:'2px 10px', color:totalAmount - ((calculateTotalWithLucky(digitMadeArr)) * 
                  parseInt(userType?.rate)) - (totalAmount/100)*parseInt(userType?.commission) < 0? 'red':'black'}}
                >{totalAmount - ((calculateTotalWithLucky(digitMadeArr) === undefined?0:calculateTotalWithLucky(digitMadeArr)) * 
                  parseInt(userType?.rate)) - (totalAmount/100)*parseInt(userType?.commission)}</td>
              </tr>
            </tbody>
          </table>
          <br/>
        </div>
        <div style={{marginTop:5, paddingLeft:10, paddingRight:10}}>
          <h4 style={{marginBottom:5, fontFamily:'mmfont'}}>စာရင်းအချုပ် (တစ်ပတ်စာ)</h4>
          <div style={{overflow:'scroll'}}>
          <table>
            <tbody>
              <tr className='number'>
                <td style={{minWidth:'90px !important'}}></td>
                <td style={{border:'1px solid #6798c6',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentMonday?.split('-')?.[2]}</td>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentTueday?.split('-')?.[2]}</td>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentWedday?.split('-')?.[2]}</td>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentThuday?.split('-')?.[2]}</td>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}} colSpan={2}>{currentFriday?.split('-')?.[2]}</td>
                
              </tr>
              <tr className='number'>
                <td style={{minWidth:'90px !important'}}></td>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>AM</td>
                  <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', textAlign:'center'}}>PM</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', minWidth:'90px !important',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>အရောင်း</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{
                    mondayAm?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
                  }</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{
                    mondayPm?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
                  }</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{
                    tueDayAm?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
                  }</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{
                    tueDayPm?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
                  }</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{
                    wedDayAm?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
                  }</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{
                    wedDayPm?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
                  }</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{
                    thuDayAm?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
                  }</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{
                    thuDayPm?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
                  }</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{
                    friDayAm?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
                  }</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{
                    friDayPm?.reduce((n, {amount}) => n + (parseInt(amount) || 0), 0)
                  }</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', minWidth:'100px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>ကော်မရှင်</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{(mondayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{(mondayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{(tueDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{(tueDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{(wedDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{(wedDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{(thuDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{(thuDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{(friDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{(friDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', minWidth:'100px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>ဒဲ့ပေါက်</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLuckyAll(mondayAm, mondayAmLuck) === undefined?0:calculateTotalWithLuckyAll(mondayAm, mondayAmLuck)}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLuckyAll(mondayPm, mondayPmLuck) === undefined?0:calculateTotalWithLuckyAll(mondayPm, mondayPmLuck)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLuckyAll(tueDayAm, tueDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(tueDayAm, tueDayAmLuck)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLuckyAll(tueDayPm, tueDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(tueDayPm, tueDayPmLuck)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLuckyAll(wedDayAm, wedDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(wedDayAm, wedDayAmLuck)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLuckyAll(wedDayPm, wedDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(wedDayPm, wedDayPmLuck)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLuckyAll(thuDayAm, thuDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(thuDayAm, thuDayAmLuck)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLuckyAll(thuDayPm, thuDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(thuDayPm, thuDayPmLuck)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLuckyAll(friDayAm, friDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(friDayAm, friDayAmLuck)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:'black', padding:'2px 10px'}}>{calculateTotalWithLuckyAll(friDayPm, friDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(friDayPm, friDayPmLuck)}</td>
              </tr>
              <tr className='number'>
                <td style={{border:'1px solid #6798c6', minWidth:'100px',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>အချုပ်</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:(mondayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(mondayAm, mondayAmLuck) === undefined?0:calculateTotalWithLuckyAll(mondayAm, mondayAmLuck)) * 
                  parseInt(userType?.rate)) - (mondayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission) < 0? 'red':'black', 
                  padding:'2px 10px'}}>{(mondayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(mondayAm, mondayAmLuck) === undefined?0:calculateTotalWithLuckyAll(mondayAm, mondayAmLuck)) * 
                    parseInt(userType?.rate)) - (mondayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:(mondayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - (calculateTotalWithLuckyAll(mondayPm, mondayPmLuck) === undefined?0:(calculateTotalWithLuckyAll(mondayPm, mondayPmLuck)) * 
                  parseInt(userType?.rate)) - (mondayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission) < 0? 'red':'black', 
                  padding:'2px 10px'}}>{(mondayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(mondayPm, mondayPmLuck) === undefined?0:calculateTotalWithLuckyAll(mondayPm, mondayPmLuck)) * 
                  parseInt(userType?.rate)) - (mondayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color:(tueDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(tueDayAm, tueDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(tueDayAm, tueDayAmLuck)) * 
                  parseInt(userType?.rate)) - (tueDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission) < 0? 'red':'black',
                  padding:'2px 10px'}}>{(tueDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(tueDayAm, tueDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(tueDayAm, tueDayAmLuck)) * 
                  parseInt(userType?.rate)) - (tueDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color: (tueDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(tueDayPm, tueDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(tueDayPm, tueDayPmLuck)) * 
                  parseInt(userType?.rate)) - (tueDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission) < 0? 'red':'black',
                  padding:'2px 10px'}}>{(tueDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(tueDayPm, tueDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(tueDayPm, tueDayPmLuck)) * 
                  parseInt(userType?.rate)) - (tueDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color: (wedDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(wedDayAm, wedDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(wedDayAm, wedDayAmLuck)) * 
                  parseInt(userType?.rate)) - (wedDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission) < 0? 'red':'black', 
                  padding:'2px 10px'}}>{(wedDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(wedDayAm, wedDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(wedDayAm, wedDayAmLuck)) * 
                  parseInt(userType?.rate)) - (wedDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color: (wedDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(wedDayPm, wedDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(wedDayPm, wedDayPmLuck)) * 
                  parseInt(userType?.rate)) - (wedDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission) < 0? 'red':'black', 
                  padding:'2px 10px'}}>{(wedDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(wedDayPm, wedDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(wedDayPm, wedDayPmLuck)) * 
                  parseInt(userType?.rate)) - (wedDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color: (thuDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(thuDayAm, thuDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(thuDayAm, thuDayAmLuck)) * 
                  parseInt(userType?.rate)) - (thuDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission) < 0? 'red':'black', 
                  padding:'2px 10px'}}>{(thuDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(thuDayAm, thuDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(thuDayAm, thuDayAmLuck)) * 
                  parseInt(userType?.rate)) - (thuDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color: (thuDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(thuDayPm, thuDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(thuDayPm, thuDayPmLuck)) * 
                  parseInt(userType?.rate)) - (thuDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission) < 0? 'red':'black', 
                  padding:'2px 10px'}}>{(thuDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(thuDayPm, thuDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(thuDayPm, thuDayPmLuck)) * 
                  parseInt(userType?.rate)) - (thuDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color: (friDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(friDayAm, friDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(friDayAm, friDayAmLuck)) * 
                  parseInt(userType?.rate)) - (friDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission) < 0? 'red':'black', 
                  padding:'2px 10px'}}>{(friDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(friDayAm, friDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(friDayAm, friDayAmLuck)) * 
                  parseInt(userType?.rate)) - (friDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
                  <td style={{border:'1px solid #6798c6', textAlign:'right',
                  margin:1, color: (friDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(friDayPm, friDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(friDayPm, friDayPmLuck)) * 
                  parseInt(userType?.rate)) - (friDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission) < 0? 'red':'black', 
                  padding:'2px 10px'}}>{(friDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(friDayPm, friDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(friDayPm, friDayPmLuck)) * 
                  parseInt(userType?.rate)) - (friDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission)}</td>
              </tr>
              <tr>
                <td style={{border:'1px solid #6798c6', 
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>စုစုပေါင်း</td>
                <td colSpan={3} style={{border:'1px solid #6798c6',
                  margin:1, backgroundColor:'white', color:'black', padding:'2px 10px', fontFamily:'mmfont'}}>(တစ်ပတ်စာ) {` -> `} {
                    ((mondayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(mondayAm, mondayAmLuck) === undefined?0:calculateTotalWithLuckyAll(mondayAm, mondayAmLuck)) * 
                    parseInt(userType?.rate)) - (mondayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission))
                    +
                    ((mondayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(mondayPm, mondayPmLuck) === undefined?0:calculateTotalWithLuckyAll(mondayPm, mondayPmLuck)) * 
                    parseInt(userType?.rate)) - (mondayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission))
                    +
                    ((tueDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(tueDayAm, tueDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(tueDayAm, tueDayAmLuck)) * 
                    parseInt(userType?.rate)) - (tueDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission))
                    +
                    ((tueDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(tueDayPm, tueDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(tueDayPm, tueDayPmLuck)) * 
                    parseInt(userType?.rate)) - (tueDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission))
                    +
                    ((wedDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(wedDayAm, wedDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(wedDayAm, wedDayAmLuck)) * 
                    parseInt(userType?.rate)) - (wedDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission))
                    +
                    ((wedDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(wedDayPm, wedDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(wedDayPm, wedDayPmLuck)) * 
                    parseInt(userType?.rate)) - (wedDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission))
                    +
                    ((thuDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(thuDayAm, thuDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(thuDayAm, thuDayAmLuck)) * 
                    parseInt(userType?.rate)) - (thuDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission))
                    +
                    ((thuDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(thuDayPm, thuDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(thuDayPm, thuDayPmLuck)) * 
                    parseInt(userType?.rate)) - (thuDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission))
                    +
                    ((friDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(friDayAm, friDayAmLuck) === undefined?0:calculateTotalWithLuckyAll(friDayAm, friDayAmLuck)) * 
                    parseInt(userType?.rate)) - (friDayAm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission))
                    +
                    ((friDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)) - ((calculateTotalWithLuckyAll(friDayPm, friDayPmLuck) === undefined?0:calculateTotalWithLuckyAll(friDayPm, friDayPmLuck)) * 
                    parseInt(userType?.rate)) - (friDayPm?.reduce((n, {amount}) => n + parseInt(amount), 0)/100)*parseInt(userType?.commission))
                  }</td>
              </tr>
            </tbody>
          </table>
          </div>
          <br/>
        </div>
      </div>
    </div>
  )
}

export default HomeScreen
