import React, { useEffect, useState, useMemo } from 'react';
import './HomeScreen.css';
import TextField from '@mui/material/TextField';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Divider, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import ThreeResultEditable from './ThreeResultEditable';
import Slide from '@mui/material/Slide';
import { EditNoteRounded, LaunchRounded } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ThreeHomeScreen() {
  let wonAudio = new Audio('./songs/win.wav')
  let loseAudio = new Audio('./songs/lose.wav')
  const navigate = useNavigate();
  const {authTokens, userType, handleLogout, threeDate} = useAuth()
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
  const [currentTime, setCurrentTime] = useState(moment().tz('Asia/Yangon').format('HH:mm:ss'))
  var currentDay = moment().tz('Asia/Yangon').format('YYYY-MM-DD')
  const [result, setResult] = useState('')
  const [editResult, setEditResult] = useState(result)
  const [history, setHistory] = useState([])
  const [historyAll, setHistoryAll] = useState([])
  const [lucky, setLucky] = useState('')
  const [hotNum, setHotNum] = useState([])
  const [takeLoad, setTakeLoad] = useState(0)
  const [errorMes, setErrorMes] = useState('')
  const [success, setSuccess] = useState(false)
  const [limitAmount, setLimitAmount] = useState('0')
  const [realTimeUser, setRealTimeUser] = useState([])
  var inputDate = moment().tz('Asia/Yangon').format();
  var closedTime = userType?.closeTime
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [passOpen, setPassOpen] = useState(false);
  const [passScroll, setPassScroll] = React.useState('paper');
  const [newpassword, setnewPassword] = React.useState('');
  const [repassword, setRePassword] = React.useState('');

  const handleClickOpen = () => {
    setScroll('paper');
    setOpen(true);
  };
  const handlePassClickOpen = () => {
    setPassScroll('paper');
    setPassOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePassClose = () => {
    setPassOpen(false);
  };

  const [editOpen, setEditOpen] = useState(false)

  const handleEditOpen = () => {
    setEditOpen(true)
  }

  const handleEditClose = () => {
    setEditOpen(false)
  }

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const numarray = Array(1000).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '00'+String(n+i)
    }else if((n + i) >= 10 && (n + i) < 100) {
      return '0'+String(n+i)
    } 
    else {
      return String(n + i)
    }
  })

  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 4
      } else if(data?.includes('A') ||
      data?.includes('H')
      ) {
        return 1
      } else if(data?.includes('F') || data?.includes('M') || data?.includes('L') ){
        return 2
      } 
      else {
        return 3
      }
    }
  }
  const mixingDigit = (digit, first, second) => {
    var arr = digit?.split('');
    const makeDigit = [
      arr[0]+''+arr[1]+''+arr[2],
      arr[0]+''+arr[2]+''+arr[1],
      arr[1]+''+arr[0]+''+arr[2],
      arr[1]+''+arr[2]+''+arr[0],
      arr[2]+''+arr[0]+''+arr[1],
      arr[2]+''+arr[1]+''+arr[0],
    ]
    const digitArr = [...new Set(makeDigit)];
    const outputArray = digitArr.map((item, index) => {
      if(index === 0){
        return ({ 'digit': item, 'amount': first })
      } else {
        return ({ 'digit':item, 'amount': second })
      }
    });
    return outputArray
  }

  var splitLen = defineSplitlens(result);
  const DefineDigit = (digit, result, numarray, amount, first, second) => {
    if(result?.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,3)
      return mixingDigit(firstFirst, first, second)
    } else if(digit.includes('A')){
      return ['000','111','222','333','444','555','666','777','888','999'].map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('H')){
      return ['000','100','200','300','400','500','600','700','800','900'].map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('M')){
      var newMDigit = digit?.replace('M','')
      var digitMFirst = numarray.filter(num => num?.[1] === newMDigit)
      return digitMFirst.map(item => ({'digit':item,'amount':amount}))
    } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[2] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount}))
    } else if(result?.includes('S')){
      var splitSDigit = result?.split('S')
      var amountS = splitSDigit?.[0]?.length === 0? 
      splitSDigit?.[1]?.slice(2) :
      splitSDigit?.[0]?.length === 1 ?
      splitSDigit?.[1]?.slice(1) :
      splitSDigit?.[1]
      var firstDigit = ''
      var secondDigit = ''
      var thirdDigit = ''
      var sDigitArr = []
      if(splitSDigit?.[0]?.length === 0){
        secondDigit = splitSDigit?.[1]?.[0]
        thirdDigit = splitSDigit?.[1]?.[1]
        sDigitArr = ['0'+secondDigit+thirdDigit, 
          '1'+secondDigit+thirdDigit,
          '2'+secondDigit+thirdDigit,
          '3'+secondDigit+thirdDigit,
          '4'+secondDigit+thirdDigit,
          '5'+secondDigit+thirdDigit,
          '6'+secondDigit+thirdDigit,
          '7'+secondDigit+thirdDigit,
          '8'+secondDigit+thirdDigit,
          '9'+secondDigit+thirdDigit,
        ]
      }else if(splitSDigit?.[0]?.length === 1){
        firstDigit = splitSDigit?.[0]
        thirdDigit = splitSDigit?.[1]?.[0]
        sDigitArr = [firstDigit+'0'+thirdDigit, 
          firstDigit+'1'+thirdDigit,
          firstDigit+'2'+thirdDigit,
          firstDigit+'3'+thirdDigit,
          firstDigit+'4'+thirdDigit,
          firstDigit+'5'+thirdDigit,
          firstDigit+'6'+thirdDigit,
          firstDigit+'7'+thirdDigit,
          firstDigit+'8'+thirdDigit,
          firstDigit+'9'+thirdDigit,
        ]
      }else {
        firstDigit = splitSDigit?.[0]?.[0]
        secondDigit = splitSDigit?.[0]?.[1]
        sDigitArr = [firstDigit+secondDigit+'0', 
          firstDigit+secondDigit+'1',
          firstDigit+secondDigit+'2',
          firstDigit+secondDigit+'3',
          firstDigit+secondDigit+'4',
          firstDigit+secondDigit+'5',
          firstDigit+secondDigit+'6',
          firstDigit+secondDigit+'7',
          firstDigit+secondDigit+'8',
          firstDigit+secondDigit+'9',
        ]
      }
      return sDigitArr.map(item => ({'digit':item,'amount':amountS}))
    } else if(result?.includes('E')){
      var newEDigit = result?.split('E')?.[1]?.split(',')
      var amountE = result?.split('E')?.[2]
      var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
      var modEArr = []
      if(amountE?.includes('R')){
        newEDigit?.forEach((digit) => {
          modEArr?.push(...mixingDigit(digit, amounts?.[0], amounts?.[1]))
        })
      } else {
        newEDigit?.forEach((digit) => {
          modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
        })
      }
      return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount}))
    }
    return [{'digit':digit, 'amount':amount}]
  }

	useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryFilter&userid='+userType?.id+'&adminid='+userType?.adminid+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveDigitLimitUser&userid='+userType?.id+'&adminid='+userType?.adminid
                      }),
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLucky&adminid='+userType?.adminid
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLimitAllDigit&adminid='+userType?.adminid
                      }),
                      /*
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryAllFilter&adminid='+userType?.adminid+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                      */
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserdetail&username='+userType?.username
                      })
                    ]).then(response => {
                        //setHistory(response?.[0]?.['data']['data']['result'])
                        setHotNum(response?.[0]?.['data']['data']['result'])
                        //var data = response?.[2]?.['data']['data']['result']
                        //var newdata = data?.filter((dat) => dat?.daypart === threeDate?.part && dat?.date === threeDate?.month)
                        //setLucky(newdata?.[0]?.lucky)
                        var nextdata = response?.[1]?.['data']['data']['result']
                        setLimitAmount(nextdata?.[0]?.limit_amount === undefined || nextdata===null?'0':nextdata?.[0]?.limit_amount)
                        //setHistoryAll(response?.[4]?.['data']['data']['result'])
                        setRealTimeUser(response?.[2]?.['data']['data']['result'])
                      })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
    }, [takeLoad, currentDate, userType?.id, userType?.adminid, userType?.username])
  
    useEffect(() => {
      let isSubscribed = true;
          if(isSubscribed){
              (async function(){
                  try{
                      await axios.all([
                        axios({
                          method:'get',
                          url: mainUrl+'api.php?op=threesaveHistoryFilter&userid='+userType?.id+'&adminid='+userType?.adminid+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                        }),
                        /*
                        axios({
                          method:'get',
                          url: mainUrl+'api.php?op=threesaveDigitLimitUser&userid='+userType?.id+'&adminid='+userType?.adminid
                        }),
                        */
                        axios({
                          method:'get',
                          url: mainUrl+'api.php?op=threegetLucky&adminid='+userType?.adminid
                        }),
                        /*
                        axios({
                          method:'get',
                          url: mainUrl+'api.php?op=threegetLimitAllDigit&adminid='+userType?.adminid
                        }),
                        */
                        axios({
                          method:'get',
                          url: mainUrl+'api.php?op=threesaveHistoryAllFilter&adminid='+userType?.adminid+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                        }),
                        /*
                        axios({
                          method:'get',
                          url: mainUrl+'api.php?op=threeuserdetail&username='+userType?.username
                        })
                        */
                      ]).then(response => {
                          setHistory(response?.[0]?.['data']['data']['result'])
                          //setHotNum(response?.[1]?.['data']['data']['result'])
                          var data = response?.[1]?.['data']['data']['result']
                          var newdata = data?.filter((dat) => dat?.daypart === threeDate?.part && dat?.date === threeDate?.month)
                          setLucky(newdata?.[0]?.lucky)
                          /*
                          var nextdata = response?.[3]?.['data']['data']['result']
                          setLimitAmount(nextdata?.[0]?.limit_amount === undefined || nextdata===null?'0':nextdata?.[0]?.limit_amount)
                          */
                          setHistoryAll(response?.[2]?.['data']['data']['result'])
                          //setRealTimeUser(response?.[5]?.['data']['data']['result'])
                        })
                  } catch (error) {
                      
                  }
              })()
          }
      return () => {isSubscribed = false}
    }, [takeLoad, userType?.id, userType?.adminid, threeDate?.dateFrom, threeDate?.dateTo, threeDate?.part, threeDate?.month])

  const useFilteredDetail = (detailAll) => {
    return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];
  
      return detailAll.filter((his) => {
        return his?.buy === '1';
      });
    }, [detailAll]);
  };

  const newHistory = useFilteredDetail(history);
  const newHistoryAll = useFilteredDetail(historyAll)

  const MakeDigitArr = () => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');
        digits?.push(digitArr)
    })
    return digits?.flat()
  }

  const MakeDigitArrAll = () => {
    var digits = []
    newHistoryAll?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');
        digits?.push(digitArr)
    })
    return digits?.flat()
  }

  const digitMadeArr = MakeDigitArr()
  const digitMadeArrAll = MakeDigitArrAll()

  const useDetailAmount = (newDetail) => {
    return useMemo(() => {
      const detailAmount = newDetail.reduce((acc, { amount, digit, ...rest }) => {
        const key = digit;
        if (!acc[key]) {
          acc[key] = { digit, ...rest, amount: 0 };
        }
        acc[key].amount += parseInt(amount, 10);
        return acc;
      }, {});
  
      return Object.values(detailAmount);
    }, [newDetail]);
  };

  const detailAmount = useDetailAmount(digitMadeArrAll);
  
  const CheckWithDigit = (digit) => {
    return detailAmount?.filter((detail) => detail?.digit === digit) || []
  }

  useEffect(() => {
    if(result?.split('\n')?.length === 1) {
      var spl = result?.split('R')
      if(spl?.length > 2){
        reset()
      }else if(result?.includes('H') && result?.includes('R')) {
        reset()
      }else if(result?.includes('A') && result?.includes('R')) {
        reset()
      }else if(result?.includes('L') && result?.includes('R')) {
        reset()
      }else if(result?.includes('F') && result?.includes('R')) {
        reset()
      }else if(result?.includes('M') && result?.includes('R')) {
        reset()
      }else if(result?.includes('R') && result?.length < 4){
        reset()
      }else if(result?.includes('R')){
        if(result?.[0] === result?.[1] 
          & result?.[0] === result?.[2]
        ){
          reset()
        }
      }else if(result?.includes('S')){
        if((result?.indexOf('S') === 0 || result?.indexOf('S') === 1 || result?.indexOf('S') === 2) && (result?.match(/S/g) || []).length === 1) {
          //continue
        }else {
          reset()
        }
      }else if(result?.includes('EE')){
        reset()
      }
    }
  },[result, splitLen])

  const onClick = (button) => {
    if(button === "Save") {
      SaveDigit();
    }
    else if(button === "C") {
      setErrorMes('')
      reset();
    }
    else {
      if(button === 'A' ||
      button === 'H'
      ) {
        setResult(button)
      } else if (button === "F" ||
        button === "L" ||
        button === "M"
      ) {
        if(result?.length > 0) {
          if(result?.[0] === '0' ||
          result?.[0] === '1' ||
          result?.[0] === '2' ||
          result?.[0] === '3' ||
          result?.[0] === '4' ||
          result?.[0] === '5' ||
          result?.[0] === '6' ||
          result?.[0] === '7' ||
          result?.[0] === '8' ||
          result?.[0] === '9'
          ) {
            setResult(result?.[0]+button)
          } else {
            reset()
          }
        } else {
          reset()
        }
      } else if(button === 'R') {
        setResult(result+button)
      }
      else if(button === 'E'){
        if(result?.length > 0){
          if(result?.[0] === 'E'){
            var substringBetweenEs = result?.split('E')?.[1]
            var chunks = substringBetweenEs?.match(/\d{3}/g);
            var formattedSubstring = chunks?.join(',');
            if(formattedSubstring === undefined){
              reset()
            }else {
              var wantstring = result.replace(substringBetweenEs, formattedSubstring);
              setResult(wantstring+button)
            }
          } else {
            setResult(button)
          }
        } else {
          setResult(result+button)
        }
      }
      else {
        setResult(result+button)
      }
    }
  }
  const reset = () => {
    setResult('')
  }

  var newDigit = result?.split('R')
  var firstSplit = newDigit?.[0]
  const digitArrForResult = DefineDigit(result?.slice(0,splitLen), result, numarray, result?.slice(defineSplitlens(result)), result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');
  //console.log(digitArrForResult)
  var overdigit = [];
  /*
  var limitAmountCheck = digitArrForResult?.some((digit) => {
    var amountcheck = Array.from(digitMadeArr?.reduce(
      (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
    ), ([digit, amount]) => ({digit, amount}));
    var hotamount = hotNum !== null? Array.from(hotNum?.reduce(
      (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
    ), ([digit, amount]) => ({digit, amount})):[];
    var getDigitAmount = amountcheck?.filter((getDigit) => getDigit?.digit === digit?.digit)
    
    var getHotNumber = hotamount?.filter((hot) => hot?.digit === digit?.digit)
    overdigit = [{
      hotAmount: getHotNumber?.[0]?.amount,
      digit:digit,
      digitamount: getDigitAmount?.[0]?.amount
    }]
    return getHotNumber?.length > 0 && hotNum !== null? 
    getHotNumber?.some((hot) => {
      var getDigit = amountcheck?.filter((a) => a?.digit === hot?.digit)
      return ((getDigit?.[0]?.amount!==undefined? getDigit?.[0]?.amount:0)+
      (parseInt(digit?.amount))) > 
      hot?.amount
    }):
    getDigitAmount?.length > 0?
    getDigitAmount?.some((sumdigit) => {
      return (
        (sumdigit?.amount + parseInt(digit?.amount)) > parseInt(userType?.limit)
      )
    }):
    parseInt(digit?.amount) > parseInt(userType?.limit)
  })
  */
  
  const LimitCheckOver = (digitArrForResult, digitMadeArr, hotNum, userType) => {
    return digitArrForResult?.some((digit) => {
      var amountcheck = Array.from(digitMadeArr?.reduce(
        (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
      ), ([digit, amount]) => ({digit, amount}));
      var hotamount = hotNum !== null? Array.from(hotNum?.reduce(
        (m, {digit, amount}) => m.set(digit, (m.get(digit) || 0) + parseInt(amount)), new Map()
      ), ([digit, amount]) => ({digit, amount})):[];
      var getDigitAmount = amountcheck?.filter((getDigit) => getDigit?.digit === digit?.digit)
      
      var getHotNumber = hotamount?.filter((hot) => hot?.digit === digit?.digit)
      overdigit = [{
        hotAmount: getHotNumber?.[0]?.amount,
        digit:digit,
        digitamount: getDigitAmount?.[0]?.amount
      }]
      return getHotNumber?.length > 0 && hotNum !== null? 
      getHotNumber?.some((hot) => {
        var getDigit = amountcheck?.filter((a) => a?.digit === hot?.digit)
        return ((getDigit?.[0]?.amount!==undefined? getDigit?.[0]?.amount:0)+
        (parseInt(digit?.amount))) > 
        hot?.amount
      }):
      getDigitAmount?.length > 0?
      getDigitAmount?.some((sumdigit) => {
        return (
          (sumdigit?.amount + parseInt(digit?.amount)) > parseInt(userType?.limit)
        )
      }):
      parseInt(digit?.amount) > parseInt(userType?.limit)
    })
  }
  /*
  var checkLimitOver = digitArrForResult?.filter((digit) => {
    return (
      (parseInt(CheckWithDigit(digit?.digit)?.[0]?.amount === undefined ? '0':CheckWithDigit(digit?.digit)?.[0]?.amount)) + parseInt(digit?.amount===undefined?'0':digit?.amount)) > parseInt(limitAmount)
  })
  */
  //console.log(limitAmountCheck)
  /*
  var checkLimitCorrect = digitArrForResult?.filter((digit) => {
    return (parseInt(digit?.amount) || 0) < 1
  })
  */
  //var digitAmountOver = ''
  /*
  var checkDigitAmountOver = digitArrForResult?.some((element) => {
    if(realTimeUser?.[0]?.closedDigit?.split(',')?.includes(element?.digit)){
      digitAmountOver = element
    }
    return realTimeUser?.[0]?.closedDigit?.split(',')?.includes(element?.digit)
  }
  )
  */
  
  const SaveDigit = async() => {
    let messageUrl = mainUrl+'api.php?op=threesaveDigit';
    setErrorMes('')
    var errorArr = []
    try{
      const promises = result?.split('\n')?.map(async(res) => {
        var splitLen = defineSplitlens(res);
        var newDigit = res?.split('R')
        var firstSplit = newDigit?.[0]
        var digitArrForResult = DefineDigit(res?.slice(0,splitLen), res, numarray, res?.slice(defineSplitlens(res)), res?.includes('R') && res?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):res?.slice(splitLen), res?.includes('R')? newDigit?.[1]:'0');
        var checkLimitCorrect = digitArrForResult?.filter((digit) => {
          return (parseInt(digit?.amount) || 0) < 1 || digit?.digit?.replace(/[^0-9]/g, '')?.length !== 3 || /[a-zA-Z]/.test(digit?.amount)
        })
        var checkLimitOver = digitArrForResult?.filter((digit) => {
          return (
          (parseInt(CheckWithDigit(digit?.digit)?.[0]?.amount === undefined ? '0':CheckWithDigit(digit?.digit)?.[0]?.amount)) + parseInt(digit?.amount===undefined?'0':digit?.amount)) > parseInt(limitAmount)
        })
        var digitAmountOver = ''
        var limitCheckOver = LimitCheckOver(digitArrForResult, digitMadeArr, hotNum, userType)
        var checkDigitAmountOver = digitArrForResult?.some((element) => {
            if(realTimeUser?.[0]?.closedDigit?.split(',')?.includes(element?.digit)){
              digitAmountOver = element
            }
            return realTimeUser?.[0]?.closedDigit?.split(',')?.includes(element?.digit)
          }
        )
      
        if(checkDigitAmountOver){
          //loseAudio.play()
          //setSuccess(false)
          errorArr?.push(digitAmountOver?.digit+' -> ဒိုင်မှ ပိတ်ထားပါတယ်!')
        }else {
          if(checkLimitOver?.length > 0){
              //loseAudio.play()
              //setSuccess(false)
              errorArr?.push(checkLimitOver?.[0]?.digit+' -> သတ်မှတ်ပမာဏ ကျော်နေပါတယ်!')
          } else {
            // amount correct
            if(checkLimitCorrect?.length > 0 || digitArrForResult?.length < 1){
              //loseAudio.play()
              //setSuccess(false)
              errorArr?.push('ပမာဏ မမှန်ကန်ပါ')
            } else {
              if(limitCheckOver){
                //loseAudio.play()
                //setSuccess(false)
                errorArr?.push(overdigit?.[0]?.digit?.digit+' (ကျန်ပမာဏ - '+
                (overdigit?.[0]?.hotAmount !== undefined && overdigit?.[0]?.digitamount !== undefined? overdigit?.[0]?.hotAmount - overdigit?.[0]?.digitamount : 
                overdigit?.[0]?.hotAmount !== undefined && overdigit?.[0]?.digitamount === undefined? overdigit?.[0]?.hotAmount - 0:
                overdigit?.[0]?.hotAmount === undefined && overdigit?.[0]?.digitamount !== undefined? parseInt(userType?.limit) - overdigit?.[0]?.digitamount:
                parseInt(userType?.limit))+')')
              } else {
                const response = await fetch(messageUrl, {
                  method:'post',
                  headers:{
                    'Content-Type':'application/x-www-form-urlencoded'
                  },
                  body:"result="+res+"&userid="+userType?.id+"&date="+inputDate+"&buy=1&adminid="+userType?.adminid+"&part="+threeDate?.part
                })
                const data = await response.json()
                return data
              }
            }
          }
        }
      })
      await Promise.allSettled(promises)
      if(errorArr?.length > 0){
        loseAudio.play()
        setResult('')
        setSuccess(false)
        setErrorMes(errorArr)
        setTakeLoad(takeLoad+1)
      }else {
        wonAudio.play()
        setResult('')
        setTakeLoad(takeLoad+1)
        setErrorMes([result+' သိမ်းပြီး!'])
        setSuccess(true)
      }
    }
    catch (error) {

    }
	}

  useEffect(() => {
    setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
    setInterval(() => setCurrentTime(moment().tz('Asia/Yangon').format('HH:mm:ss')),5000)
  },[])
  function DigitStatus() {
    if(realTimeUser?.[0]?.status === 'Open'){
      if(currentDay >= threeDate?.dateFrom && currentDay <= threeDate?.dateTo) {
        if(currentDay === threeDate?.dateTo){
          if(currentTime <= closedTime){
            return 'Open'
          }
          else {
            return 'Closed'
          }
        }
        else {
          return 'Open'
        }
      } else {
        return 'Closed'
      }
    } else {
      return 'Closed'
    }
  }
  var digitStatus = DigitStatus()
  //var digitStatus = 'Open'

  var totalAmount = digitMadeArr?.reduce((n, {amount}) => n + parseInt(amount), 0)

  const calculateTotalWithLucky = (arr) => {
    var searchUser = arr?.filter((user) => user?.digit === lucky)
    var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
    return totalAmount
  }

  const defineDigit = (digit) => { 
    if(digit < 10){
      return '00'+String(digit)
    }else if(digit >= 10 && digit < 100) {
      return '0'+String(digit)
    } 
    else {
      return String(digit)
    }
  }

  const mixingDigitLuck = (digit) => {
    var arr = digit === undefined || digit?.length < 1? []: digit?.split('');
    if(arr?.length > 0){
      return [
        arr[0]+''+arr[1]+''+arr[2],
        arr[0]+''+arr[2]+''+arr[1],
        arr[1]+''+arr[0]+''+arr[2],
        arr[1]+''+arr[2]+''+arr[0],
        arr[2]+''+arr[0]+''+arr[1],
        arr[2]+''+arr[1]+''+arr[0],
      ]
    }
    return []
  }
  
  var nearArrFull = [...new Set(mixingDigitLuck(lucky))];
  var nearArr = nearArrFull?.length > 0? nearArrFull?.filter((arr) => arr !== lucky) :[]
  var upDigit = parseInt(lucky) === 999? false:true
  var downDigit = parseInt(lucky) < 1? false:true
  downDigit? nearArr?.push(defineDigit(parseInt(lucky)-1)) : nearArr?.push('999')
  upDigit ? nearArr?.push(defineDigit(parseInt(lucky)+1)) : nearArr?.push('000')
  
  
  const calculateTotalWithLuckyNear = (arr) => {
    var searchUser = arr?.filter((user) => nearArr?.includes(user?.digit)
    )
    var totalAmount = searchUser?.reduce((n, {amount}) => n + parseInt(amount), 0)
    return totalAmount
  }

  const [updateMes, setUpdateMes] = useState('')
  const [successMes, setSuccessMes] = useState('')
  const updatePass = async() => {
    let updateUrl = mainUrl+'api.php?op=threechangePass';
    if(newpassword!=='' || repassword !== ''){
      if(newpassword === repassword){
        await fetch(updateUrl, {
          method:'post',
          headers:{
            'Content-Type':'application/x-www-form-urlencoded'
          },
          body:"password="+newpassword+"&id="+userType?.id
        }).then(() => {
          handlePassClose()
          setnewPassword('')
          setRePassword('')
          setUpdateMes('')
          setSuccessMes('Password ချိန်းပီးပါပီ')
          handleLogout()
        })
      }else {
        setUpdateMes('Password တူအောင်ပြန်ရိုက်ထည့်ပေးပါ')
        setSuccessMes('')
      }
      }else {
        setUpdateMes('Password အသစ်ရိုက်ထည့်ပေးပါ')
        setSuccessMes('')
    }
  }

  return (
    <div
    style={{display:'flex', 
        justifyContent:'center', paddingBottom:10,
        alignItems:'start'}}
    >
      <ThreeResultEditable
      editOpen = {editOpen}
      handleEditClose={handleEditClose}
      Transition={Transition}
      digitStatus = {digitStatus}
      onClick = {onClick}
      result = {result}
      setResult = {setResult}
      digitArrForResult = {digitArrForResult}
      editResult = {editResult}
      setEditResult = {setEditResult}
      defineSplitlens={defineSplitlens}
      DefineDigit={DefineDigit}
      numarray={numarray}
      limitAmount = {limitAmount}
      CheckWithDigit = {CheckWithDigit}
      LimitCheckOver = {LimitCheckOver}
      digitMadeArr = {digitMadeArr}
      hotNum = {hotNum}
      userType = {userType}
      realTimeUser = {realTimeUser}
      />
      <Dialog
      onClose={handlePassClose}
      open={passOpen}
      scroll={passScroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      >
        <DialogTitle style={{fontFamily:'mmfont', fontWeight:'900', textAlign:'center'}} sx={{ m: 0, p: 2 }} id="scroll-dialog-title">
          Password ချိန်းရန်
        </DialogTitle>
        <DialogContent>
          {updateMes!==''?<p style={{color:'red', fontFamily:'mmfont', marginBottom:5}}>{updateMes}</p>:null}
          {successMes!==''?<p style={{color:'red', fontFamily:'mmfont', marginBottom:5}}>{successMes}</p>:null}
          <div style={{display:'flex', flexDirection:'column'}}>
          <TextField
            size='small'
            autoComplete={false}
            id="outlined-basic" variant="outlined" 
            value={newpassword}
            placeholder='New Password'
            onChange={(e) => setnewPassword(e.target.value)}
            type="password"
          />
          <br/>
          <TextField 
            autoComplete={false}
            size='small'
            id="outlined-basic" variant="outlined" 
            value={repassword}
            placeholder='Confirm Password'
            onChange={(e) => setRePassword(e.target.value)}
            type="password"
          />
          </div>
        </DialogContent>
        <DialogActions>
          <Button style={{fontFamily:'mmfont'}} onClick={handlePassClose}>
            ပိတ်မည်
          </Button>
          <Button style={{fontFamily:'mmfont'}} onClick={updatePass}>
            သိမ်းမည်
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleClose}
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle style={{fontFamily:'mmfont', fontWeight:'900'}} sx={{ m: 0, p: 2 }} id="scroll-dialog-title">
          ရည်ညွှန်းချက်များ
        </DialogTitle>
        <DialogContent
        style={{marginLeft:'15px', marginRight:'15px', paddingLeft:0, paddingRight:0}}
        >
          <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
          >
            <div>
              <table>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:120, fontWeight:'900'}}>ဂဏန်း</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'mmfont',
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:180, fontWeight:'900'}}
                  >ရိုက်ကွက်</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ဒဲ့ ဂဏန်း (ဥပမာ 985-3000 ကျပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >9853000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ငွေတူ R ဂဏန်း (ဥပမာ 985-R 3000 ကျပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >985R5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ငွေမတူ R ဂဏန်း (ဥပမာ 985-ဒဲ့ 3000, R 5000 ကျပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >9853000R5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>Tri ဂဏန်းများ - 5000 ကျပ်</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >A5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ထိပ် ဂဏန်းများ (ဥပမာ 1 ထိပ် - 5000 ကျပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >1F5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>အလယ် ဂဏန်းများ (ဥပမာ 1 လယ် - 5000 ကျပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >1M5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>နောက် ဂဏန်းများ (ဥပမာ 1 နောက် - 5000 ကျပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >1L5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>ရာပြည့် ဂဏန်းများ (ဥပမာ 100,200,300 .. - 5000 ကျပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >H5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>စီးရီး ဂဏန်းများ (ဥပမာ 1 ထိပ် 2 လယ် - 5000 ကျပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >12S5000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>စီးရီး ဂဏန်းများ (ဥပမာ 1 ထိပ် 2 ပိတ် - 5000 ကျပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >1S25000</td>
                </tr>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6', fontFamily:'mmfont',
                    margin:1, backgroundColor:'white', color:'black', minWidth:180, fontWeight:'900', textAlign:'left'}}>စီးရီး ဂဏန်းများ (ဥပမာ 1 လယ် 2 ပိတ် - 5000 ကျပ်)</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'textfont',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:180}}
                  >S125000</td>
                </tr>
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
     
      <div className='homescreen'>
        <div className='digitscreen'>
          <div className='topbar'>
            <p className='text'>အမည်</p>
          </div>
          <div style={{marginLeft: 20, display:'flex', alignItems:'center'}}>
            <p className='text'>: &nbsp; {userType?.username} | 3D</p>
            <IconButton
            sx={{
              color:'white',
              '&:focus': {
                backgroundColor: 'transparent',
              },
              '&:hover': {
                backgroundColor: 'transparent',
              }
            }}
            onClick={() => {
              handlePassClickOpen()}}
            color="white" aria-label="expand"
            >
              <EditNoteRounded size={12}/>
            </IconButton>
          </div>
          <div style={{marginRight:0, marginLeft:'auto'}}>
          <Button
          style={{padding:'0px 10px', backgroundColor:'rgba(255,255,255,0.2)',
            textDecoration:'none !important', color:'white', textTransform:'capitalize'}}
          onClick={() => {navigate('/'); handleLogout()}}
          >
            Log out
          </Button>
          </div>
        </div>
        <div className='digitscreen' style={{alignItems:'self-start', justifyContent:'space-between'}}>
          <div style={{display:'flex', flexDirection:'row'}}>
          <div className='topbar'>
            <p className='text'>ဖွင့်ရက်</p>
          </div>
          <div style={{marginLeft: 20, fontSize:13}}>
            <p className='text'>: &nbsp; {threeDate?.dateFrom} မှ</p>
            <p className='text'> &nbsp; &nbsp; {threeDate?.dateTo} ထိ</p>
          </div>
          </div>
          <div style={{marginLeft: 20}}>
          <Button
          style={{padding:'0px 10px', backgroundColor:'white',
            textDecoration:'none !important', color:'black', textTransform:'lowercase'}}
          onClick={() => navigate('/')}
          >
            Select Date
          </Button>
          </div>
        </div>
        <div className='digitscreen'>
          <div className='topbar'>
            <p className='text'>ပိတ်ချိန်</p>
          </div>
          <div style={{marginLeft: 20}}>
            <p className='text'>: &nbsp; {threeDate?.dateTo} ({realTimeUser?.[0]?.closeTime === undefined? closedTime: realTimeUser?.[0]?.closeTime})</p>
          </div>
        </div>
        <div className='digitscreen'>
          <div className='topbarBottom'>
            <p className='text'>တစ်ကွက် အများဆုံး ခွင့်ပြုငွေ</p>
          </div>
          <div style={{marginLeft: 10}}>
            <p className='text'>: &nbsp; {userType?.limit}</p>
          </div>
        </div>
        <div className='digitscreen' style={{paddingBottom:10}}>
          <div className='topbar'>
            <p className='text'>Status</p>
          </div>
          <div style={{marginLeft: 20}}>
            <p className='text'>: &nbsp; {digitStatus}</p>
          </div>
          <div style={{marginLeft: 'auto', marginRight:0}}>
            <Button
            style={{padding:'0px 10px', backgroundColor:'rgba(255,255,255,0.2)', fontFamily:'mmfont',
              textDecoration:'none !important', color:'white', textTransform:'capitalize'}}
              onClick={() => handleClickOpen()}
            >
              ရည်ညွှန်းချက်များ
            </Button>
          </div>
        </div>
        <div style={{marginLeft:10, display:'flex', alignItems:'center', margin:'5px 10px'}}>
            <p style={{color:'black', marginRight:4}}>Hot Digits : &nbsp; </p>
            <div style={{display:'flex'}}>{
            hotNum?.length > 0 || hotNum !== undefined?
            hotNum?.map((hot,index) => 
            <><p style={{color:'red'}} key={index}>{hot?.digit}</p><p>{hot?.amount}{index === (hotNum?.length-1)?'':','}</p></>
            )
            :null
            }</div>
          </div>
        <div style={{padding:'3px 10px 0px 10px', display:'flex'}}>
          <p>Result: </p> <p line
          style={{color: success?'#1bbc12':'#d8005d', lineHeight:'20px', overflow:'scroll', whiteSpace:'nowrap'}}>&nbsp; {errorMes !== '' && errorMes?.map((err) => err+' , ')}</p>
        </div>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: "95%"},
          }}
          className='White'
          style={{textAlign:'center'}}
          noValidate
          autoComplete="off"
        >
        <div>
        <Box
          component="form"
          sx={{ p: '2px 0px', boxShadow:'0px', display: 'flex', alignItems: 'center', justifyContent:'center', flex:1,}}
          >
            <TextField
            size='small'
            aria-readonly
            disabled
            value={result}
            id="outlined-disabled"
            placeholder='Press any digit'
            sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                    "& > fieldset": {
                        borderColor: "rgba(0,0,0,0.1)",
                        WebkitTextFillColor: "#000000",
                    },
                    WebkitTextFillColor: "#000000",
                    fontSize:27,
                }
            }}
            InputProps={{
              style: {
                paddingTop:'0px'
              },
              sx: {
                "&.MuiInputBase-root.Mui-disabled": { color: "unset" },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "unset",
                },
              },
              autoComplete: 'off'
            }}
            />
            <Divider sx={{ height: 28 }} orientation="vertical"/>
            <IconButton
            onClick={() => {
              setEditResult(result)
              handleEditOpen()}}
            color="primary" sx={{ p: '10px'}} aria-label="expand"
            >
              <LaunchRounded/>
            </IconButton>
          </Box>
        </div>
        </Box>
        <div className='firstrow'>
            <button
            ////variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value="C"
            style={{fontFamily:'mmfont'}}
            >
            ဖျက်မည်
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "A"
            id="digitBox"
            disabled={digitStatus==="Closed"}
            >
            Tri
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "R"
            id="digitBox"
            disabled={digitStatus==="Closed"}
            >
              R
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "F"
            disabled={digitStatus==="Closed"}
            style={{fontFamily:'mmfont'}}
            >
            ထိပ်
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "7"
            disabled={digitStatus==="Closed"}
            >
            7
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "8"
            disabled={digitStatus==="Closed"}
            >
            8
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "9"
            disabled={digitStatus==="Closed"}
            >
            9
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "M"
            disabled={digitStatus==="Closed"}
            style={{fontFamily:'mmfont'}}
            >
            လယ်
            </button>
          </div>
          <div className='firstrow'>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "4"
            disabled={digitStatus==="Closed"}
            >
            4
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "5"
            disabled={digitStatus==="Closed"}
            >
            5
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "6"
            disabled={digitStatus==="Closed"}
            >
            6
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "L"
            disabled={digitStatus==="Closed"}
            style={{fontFamily:'mmfont'}}
            >
            နောက်
            </button>
          </div>
          <div className='firstrow' style={{alignItems:'flex-start', marginTop:-0.5}}>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'100%'}}
            onClick={(e) => onClick(e.target.value)}
            value = "1"
            disabled={digitStatus==="Closed"}
            >
            1
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'202.5%', marginTop:3}}
            onClick={(e) => onClick(e.target.value)}
            value = "0"
            disabled={digitStatus==="Closed"}
            >
            0
            </button>
            <div
            style={{display:'flex', flexDirection:'row', width:'202.5%'}}
            >
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            style={{width:'100%', marginTop:3, fontFamily:'mmfont', marginRight:1}}
            onClick={(e) => onClick(e.target.value)}
            value = "S"
            disabled={digitStatus==="Closed"}
            >
            စီးရီး
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            style={{width:'100%', marginTop:3, fontFamily:'mmfont', marginLeft:1}}
            onClick={(e) => onClick(e.target.value)}
            value = "H"
            disabled={digitStatus==="Closed"}
            >
            ရာပြည့်
            </button>
            </div>
            </div>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            style={{width:'100%'}}
            //disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "2"
            disabled={digitStatus==="Closed"}
            >
            2
            </button>
            </div>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            //disableRipple
            style={{width:'100%'}}
            onClick={(e) => onClick(e.target.value)}
            value = "3"
            disabled={digitStatus==="Closed"}
            >
            3
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            style={{width:'100%', marginTop:3}}
            onClick={(e) => onClick(e.target.value)}
            value = "00"
            id="digitBox"
            disabled={digitStatus==="Closed"}
            >
              00
            </button>
            <button
            //variant="contained" disableElevation
            className='buttonBox'
            //disableRipple
            style={{width:'100%', marginTop:3, fontFamily:'mmfont'}}
            onClick={(e) => onClick(e.target.value)}
            value = "E"
            //id="digitBox"
            disabled={digitStatus==="Closed"}
            >
              ငွေတူ
            </button>
            </div>
            <button
            //variant="contained" disableElevation
            className='buttonBox fourthBox'
            //disableRipple
            style={{backgroundColor:'#6798c6 !important', fontFamily:'mmfont', height:'231px'}}
            onClick={(e) => onClick(e.target.value)}
            value = "Save"
            disabled={digitStatus==="Closed" || digitArrForResult?.[0]?.digit === '' || digitArrForResult?.[0]?.amount === ''}
            >
            သိမ်းမည်
            </button>
          </div>
        <div style={{padding:10, marginTop:10, justifyContent:'space-between', display:'flex'}}>
        <Button
        size='small'
        style={{
          fontSize:14,
          textDecoration:'none',
          textTransform:'capitalize',
          color:'#6798c6',
          border:'2px solid #6798c6',
          fontWeight:'bold', 
          fontFamily:'mmfont'
        }}
        className='linkText'
        onClick={() => navigate('/history', {
          state: {
            history:history
          }
        })}
        >
          ထိုးဂဏန်းများ
        </Button>
        <Button
        size='small'
        style={{
          fontSize:14,
          textDecoration:'none',
          textTransform:'capitalize',
          color:'#6798c6',
          border:'2px solid #6798c6',
          fontWeight:'bold', 
          fontFamily:'mmfont'
        }}
        className='linkText'
        onClick={() => navigate('/details', {
          state: {
            history:history
          }
        })}
        >
          အကွက်ဇယား
        </Button>
        </div>
        <div style={{marginTop:5, paddingLeft:10}}>
          <h4 style={{marginBottom:5, fontFamily:'mmfont'}}>စာရင်းအချုပ်</h4>
          <table>
            <tbody>
              <tr>
                <td style={{border:'1px solid #6798c6', width:150,
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>အရောင်းစုစုပေါင်း</td>
                <td
                  style={{border:'1px solid #6798c6', fontSize:16, fontWeight:'500',
                  margin:1, width:150, color:'black', padding:'2px 10px', fontFamily:'textfont'}}
                >{totalAmount}</td>
              </tr>
              <tr>
                <td style={{border:'1px solid #6798c6', width:150,
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>ကော်မရှင် ({userType?.commission}%)</td>
                <td
                  style={{border:'1px solid #6798c6', fontSize:16, fontWeight:'500',
                  margin:1, width:150, color:'black', padding:'2px 10px', fontFamily:'textfont'}}
                >{(totalAmount/100)*parseInt(userType?.commission)}</td>
              </tr>
              <tr>
                <td style={{border:'1px solid #6798c6', width:150, fontFamily:'mmfont',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}>
                    ဒဲ့ပေါက် ({lucky === undefined || lucky ===""?'---':lucky})
                  </td>
                <td
                  style={{border:'1px solid #6798c6', fontSize:16, fontWeight:'500',
                  margin:1, width:150, color:'black', padding:'2px 10px', fontFamily:'textfont'}}
                >{calculateTotalWithLucky(digitMadeArr) === undefined?0:calculateTotalWithLucky(digitMadeArr)}</td>
              </tr>
              <tr>
                <td style={{border:'1px solid #6798c6', width:150,
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>တွတ်</td>
                <td
                  style={{border:'1px solid #6798c6', fontSize:16, fontWeight:'500',
                  margin:1, width:150, color:'black', padding:'2px 10px', fontFamily:'textfont'}}
                >{calculateTotalWithLuckyNear(digitMadeArr) === undefined?0:calculateTotalWithLuckyNear(digitMadeArr)}</td>
              </tr>
              <tr>
                <td style={{border:'1px solid #6798c6', width:150,
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px', fontFamily:'mmfont'}}>စုစုပေါင်း</td>
                <td
                  style={{border:'1px solid #6798c6', fontSize:16, fontWeight:'500', fontFamily:'textfont',
                  margin:1, width:150, padding:'2px 10px', color:totalAmount - ((calculateTotalWithLucky(digitMadeArrAll)===undefined?0:calculateTotalWithLucky(digitMadeArr)) * 
                  parseInt(userType?.rate)) - (calculateTotalWithLuckyNear(digitMadeArr)===undefined?0:calculateTotalWithLuckyNear(digitMadeArr))*10 - (totalAmount/100)*parseInt(userType?.commission) < 0? 'red':'black'}}
                >{totalAmount - ((calculateTotalWithLucky(digitMadeArr) === undefined?0:calculateTotalWithLucky(digitMadeArr)) * 
                  parseInt(userType?.rate)) - (calculateTotalWithLuckyNear(digitMadeArr)===undefined?0:calculateTotalWithLuckyNear(digitMadeArr))*10 - (totalAmount/100)*parseInt(userType?.commission)}</td>
              </tr>
            </tbody>
          </table>
          <br/>
        </div>
      </div>
    </div>
  )
}

export default ThreeHomeScreen
